import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { l_manifesto } from "../../locales";
import gsap, { Linear } from "gsap";

const Chip = ({ word }: { word: string }) => {
  return (
    <Box
      sx={{
        backgroundColor: "rgb(241, 244, 255)",
        transition: "background-color 300ms",
        ":hover": { backgroundColor: "rgba(255, 122, 0, 0.7)" },
        fontSize: { md: "24px", xs: "16px" },
        padding: { md: "24px 48px", xs: "12px 32px " },
        borderRadius: "120px",
        marginX: { md: "8px", xs: "4px" },
        flexShrink: 0,
        cursor: "default",
      }}
    >
      {word}
    </Box>
  );
};

const Line = ({ lineOfWords, i }: { lineOfWords: string[]; i: number }) => {
  return (
    <Stack
      sx={{ flexDirection: "row", flexShrink: 0, marginBottom: "16px" }}
      className={i % 2 === 0 ? "words-carousel" : "words-carousel-inverse"}
    >
      {lineOfWords.map((word) => (
        <Chip word={word} key={word} />
      ))}
    </Stack>
  );
};

const RotatingWords = () => {
  const listOfWords = [
    [
      l_manifesto("manyDefinitions.carouselW1"),
      l_manifesto("manyDefinitions.carouselW2"),
      l_manifesto("manyDefinitions.carouselW3"),
      l_manifesto("manyDefinitions.carouselW4"),
      l_manifesto("manyDefinitions.carouselW5"),
      l_manifesto("manyDefinitions.carouselW6"),
      l_manifesto("manyDefinitions.carouselW7"),
      l_manifesto("manyDefinitions.carouselW8"),
    ],
    [
      l_manifesto("manyDefinitions.carouselW9"),
      l_manifesto("manyDefinitions.carouselW10"),
      l_manifesto("manyDefinitions.carouselW11"),
      l_manifesto("manyDefinitions.carouselW12"),
      l_manifesto("manyDefinitions.carouselW13"),
      l_manifesto("manyDefinitions.carouselW14"),
      l_manifesto("manyDefinitions.carouselW15"),
      l_manifesto("manyDefinitions.carouselW16"),
    ],
    [
      l_manifesto("manyDefinitions.carouselW17"),
      l_manifesto("manyDefinitions.carouselW18"),
      l_manifesto("manyDefinitions.carouselW19"),
      l_manifesto("manyDefinitions.carouselW20"),
      l_manifesto("manyDefinitions.carouselW21"),
      l_manifesto("manyDefinitions.carouselW22"),
      l_manifesto("manyDefinitions.carouselW23"),
      l_manifesto("manyDefinitions.carouselW24"),
    ],
  ];

  useEffect(() => {
    gsap.fromTo(
      ".words-carousel",
      { xPercent: 0 },
      {
        xPercent: -100,
        duration: 50,
        ease: Linear.easeNone,
        repeat: -1,
      },
    );
    gsap.fromTo(
      ".words-carousel-inverse",
      { xPercent: -100 },
      {
        xPercent: 0,
        duration: 50,
        ease: Linear.easeNone,
        repeat: -1,
      },
    );
  }, []);

  return (
    <Box
      sx={{ position: "relative", marginBottom: { md: "280px", xs: "100px" } }}
    >
      {listOfWords.map((line, i) => (
        <Stack
          sx={{
            flexDirection: "row",
            position: "relative",
          }}
          key={line[0] + i}
        >
          <Line lineOfWords={line} i={i} />
          <Line lineOfWords={line} i={i} />
          <Line lineOfWords={line} i={i} />
        </Stack>
      ))}
    </Box>
  );
};

export default RotatingWords;
